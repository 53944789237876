@import "@/assets/scss/_mixins.scss";

.Input_root {
  label {
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 10px;
  }
  
  .Input_labelRow {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .Input_rightPart {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      text-transform: uppercase;
      color: #fff;
      
      span {
        color: #2af3f3;
      }
    }
    
    .Input_rightValueFee {
      margin: 0 0 0 5px;
      float: right;
    }
  }
  
  .Input_inputRow {
    .Input_copyAreaHidden {
      width: 0px;
      height: 0px;
      overflow: hidden;
    }
    
    input {
      // display: block;
      width: 100%;
      box-shadow: 0px 18px 21px rgba(34, 39, 47, 0.34);
      border-radius: 4px;
      background: rgba(#fff, 0.04);
      outline: none;
      padding: 0;
      margin: 0;
      border: none;
      padding: 15px 12px;
      color: #fff;
      font-size: 13px;
      
      &:disabled {
        background: rgba(#fff, 0.04);
        color: rgba(#fff, 0.44);
      }
    }
    
    // &.Input_hasError {
    //   input {
    //     border: 1px solid #ff556d;
    //     border-right: none;
    //   }
    // }
    &.Input_hasRightBtn {
      display: flex;
      
      input {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      
      button {
        @include resetDefaultStyles;
        cursor: pointer;
        min-width: 120px;
        box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.2);
        border-top-right-radius: 4px;
        background: #286377;
        border-bottom-right-radius: 4px;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        text-align: center;
        color: #2af3f3;
        border-radius: 1.6px;
        transition: 0.25s all ease-in-out;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          vertical-align: middle;
          margin-right: 5px;
        }
        
        &:hover {
          background: #2af3f3;
          color: #0f1e30;
        }
        
        &:disabled {
          opacity: 0.3;
          cursor: not-allowed;
          
          &:hover {
            background: #286377;
            color: #2af3f3;
          }
        }
      }
    }
    
    &.Input_hasRightBtnCopy {
      display: flex;
      position: relative;
      cursor: pointer;
      
      button {
        @include resetDefaultStyles;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        background: #163443;
        width: 24px;
        height: 24px;
        border-radius: 2px;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
        transition: 0.25s all ease-in-out;
        
        svg {
          transition: 0.25s all ease-in-out;
        }
        
        &:hover {
          background: #a5ffff;
          color: #0f1e30;
          
          svg path {
            stroke: #0f1e30;
            fill: #0f1e30;
          }
        }
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
.Waiting_wrapper {
  max-width: 880px;
  width: 100%;
  padding: 0 10px;
  @media only screen and (min-width: 768px) {
    padding: 0;
  }
  .Waiting_reload-arrow {
    display: flex;
    align-items: center;
    &::after {
      content: '';
      width: 1px;
      height: 26px;
      background: #fff;
      margin: 0 10px;
    }
  }
  .Waiting_reload {
    pointer-events: none;
    max-width: 384px;
    transition: 0.25s all ease-in-out;
    width: 100%;
    background: #2d4d70;
    box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.1);
    border-radius: 4px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 124%;
    cursor: pointer;
    margin: 0 auto;
    &:hover {
      background: #243f5e;
    }
  }
  .Waiting_warning {
    padding: 10px;
    color: #ff556d;
    font-size: 12px;
    line-height: 124%;
    display: flex;
    align-items: center;
    background: rgba(255, 85, 109, 0.1);
    border-radius: 2px;
    border: 1px solid #ff556d;
    max-width: 320px;
    margin: 0 auto 20px auto;
    @media only screen and (min-width: 768px) {
      font-size: 14px;
    }
    .InfoPseudoIcon {
      width: 12px;
      height: 12px;
    }
  }
  .Waiting_description {
    padding: 0;
    margin: 14px 0px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
  }
  .Waiting_inner {
    max-width: 420px;
    width: 100%;
    margin: 0 auto;
  }
  .Waiting_title {
    color: #fff;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    margin: 30px 0 0 0;
    justify-content: flex-end;
    @media only screen and (min-width: 768px) {
      font-size: 21px;
      justify-content: center;
      margin: 50px 0 0 0;
    }
  }

  .Waiting_border {
    max-width: 880px;
    width: 100%;
    height: 1px;
    background: #2af3f3;
    opacity: 0.1;
    border-radius: 4px;
    margin: 10px 0 30px 0;
    @media only screen and (min-width: 768px) {
      margin: 20px 0 30px 0;
    }
    &__sml {
      margin: 8px 0;
      @media only screen and (min-width: 768px) {
        margin: 14px 0;
      }
    }
  }

  .Waiting_countdown {
    color: #5abc67;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    &__inner {
      margin: 0 0 0 4px;
    }
  }
  .Waiting_transaciton {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @media only screen and (min-width: 768px) {
      flex-direction: row;
    }
  }
  .Waiting_qr {
    max-width: 102px;
    width: 100%;
    height: 102px;
    background: #fff;
    border-radius: 2px;
    margin: 0 auto 16px auto;
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: 768px) {
      margin: 0 20px 0 0;
    }
  }
  .Waiting_desc {
    text-align: left;
  }
  .Waiting_countdown {
    margin: 0 0 5px 0;
  }
  p {
    margin: 0;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.04em;
  }
  h2 {
    margin: 10px 0 6px 0;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
  }
  .Waiting_item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 28px;
    grid-template-areas: '. .';
    .Waiting_item-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 22px;
      @media only screen and (min-width: 768px) {
        font-size: 24px;
        line-height: 28px;
      }
      span {
        font-size: 10px;
        line-height: 13px;
        text-transform: uppercase;
        margin: 0 0 6px 0;
        @media only screen and (min-width: 768px) {
          font-size: 14px;
          line-height: 16px;
          margin: 0 0 10px 0;
        }
      }
    }
  }
}